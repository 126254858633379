.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


:root {
  --primary-color: rgb(165, 137, 191);
  --secondary-color: rgb(251, 248, 255);
  --text-color: rgb(56, 56, 56);
  --table-color: rgb(224, 224, 224);
  --bg-color: rgb(220, 220, 220);
}

body {
  color: var(--text-color);
  background-color: var(--bg-color);
  line-height: 1.4rem;
  font-size: 1.4rem;
  font-family: "Century Gothic", sans-serif !important;
}

h1,
h2,
h3,
h4 {
  color: var(--primary-color);
}

h1 {
  font-weight: bold !important;
  font-size: 3em;
}

h2 {
  font-weight: bold !important;
  font-size: 1.5em;
  margin-top: 1.5rem;
  margin-bottom: 0.4rem;
}

.page_header {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: left;
  vertical-align:middle;
}

.margin_left {
  margin-left: 10px;
}

.bold-purple {
  color: var(--primary-color);
  font-weight: bold !important;
  font-size: 3em;
}

.align-items-center {
  align-items: center!important;
}

.bg-purple {
  background-color: #A588BF;
}

.purple {
  color: #56388F;
}

.btn-purple {
  color: #fff;
  background-color: #56388F;
}

.btn-outline-purple {
  color: #56388F;
  border-color: #56388F;
}

.btn-outline-purple:hover {
  font-weight: bold;
  background-color: #56388F;
  border-color: #56388F;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  display: inline-block;
  min-width: 33%;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-success {
  color: #2e7501;
  background-color: #98d374;
  border-color: #5c9e33;
}
